.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  background: #d9d9d9;
  width: 100%;
  position: relative;
  border-radius: 0px 5px 0px 5px;
}
.container:before {
  content: "";
  height: 15px;
  width: 10px;
  background: #d9d9d9;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-100%);
}
.container:after {
  content: "";
  height: 15px;
  width: 10px;
  background: #d9d9d9;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
}
.arrow {
  color: #c22a23;
  font-size: 20px;
  position: absolute;
  animation: moveArrow 4s linear infinite;
  -webkit-animation: moveArrow 4s linear infinite;
  transform: translate3d(0, 0, 0);
  will-change: transform; /* Add will-change property to improve performance */
}

.secondArrow {
  animation-delay: 2s;
}
@keyframes moveArrow {
  0% {
    transform: translate3d(0, 0, 0);
    left: 0;
  }
  100% {
    left: calc(100% - 50px);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes moveArrow {
  0% {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  100% {
    left: calc(100% - 50px);
    transform: translate3d(0, 0, 0);
  }
}
