// Plugin CSS
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~animate.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "node_modules/react-modal-video/scss/modal-video.scss";

// Init main SCSS File
@import "/public/assets/scss/style.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
