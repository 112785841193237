.mainContainer {
  padding: 70px 0;
  padding-top: 110px;
}

.mainWrapper {
  background: #f2f2f2;
  max-width: 1365px;
  width: 90%;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}
.trackingNumber{
  color: #191919;
  font-size: 16px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 450;
  line-height: 120%;
  letter-spacing: -0.4px;
  margin: 0;
  padding: -2px 0;
}
.wrapper {
  max-width: 893px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid rgba(119, 119, 119, 0.89);
  background: #fff;
}
.heading {
  color: #000;
  font-size: 40px;
  font-family: Inter;
  font-style: normal;
  font-weight: 330;
  line-height: 100%;
  letter-spacing: -0.6px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 30px;
}
.inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  border: 1px solid rgba(119, 119, 119, 0.89);
  background: #fff;
  padding: 3px;
  max-width: 587px;
  width: 100%;
  margin: 0 auto;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 0 5px;
  width: 100%;
}
.label {
  color: #6e6e6e;
  font-size: 9.5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 455;
  line-height: 100%;
  letter-spacing: -0.142px;
}
.input {
  color: #191919;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 455;
  width: 100%;
  line-height: 100%;
  letter-spacing: -0.16px;
  text-transform: uppercase;
  border: none;
  outline: none;
  background: transparent;
  cursor: text;
}

.button {
  color: #fff;
  font-size: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.075px;
  border-radius: 0px 3px 3px 0px;
  background: #c22a23;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 12px 35px;
  min-width: 112px;
  min-height: 100%;
  display: block;
}
.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
.text,
.trackingCode,
.shipmentBy {
  color: #2b2b2b;
  font-size: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 455;
  line-height: 130%;
  letter-spacing: -0.13px;
  margin: 0;
}
.highlight {
  font-weight: 700;
}
.header {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}
.printButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 14px 20px;
  border-radius: 0px 3px 3px 0px;
  border: 1px solid #c22a23;
}
.printText {
  color: #c22a23;
  font-size: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.065px;
  margin: 0;
}
.header,
.shipmentInfo,
.shipmentNotification,
.shipmentUpdate {
  padding: 20px;
  border-bottom: 1px solid rgba(119, 119, 119, 0.7);
}
.title {
  color: #191919;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 761;
  line-height: 100%;
  letter-spacing: -0.5px;
  margin: 0;
}
.routeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}
.routeText {
  color: #191919;
  font-size: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 577;
  line-height: 10%;
  padding-bottom: 5px;
  letter-spacing: -0.065px;
  margin: 0;
}
.rightRoute {
  margin-left: auto;
}
.shipmentNotification {
  padding: 20px;
}
.notificationButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 14px 20px;

  border-radius: 3px;
  background: #c22a23;
  border: none;
  outline: none;
}
.notificationText {
  color: #fff;
  font-size: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin: 0;
}
.notificationIcon {
  color: #ffffff;
}

.shipmentUpdate {
  border: none;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.arrow {
  color: #c22a23;
  cursor: pointer;
}
.grid {
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 50px 0;
}
.locationContainer {
  position: relative;
}
.locationContainer::before {
  content: "";
  width: 1px;
  height: 70px;
  border: 1px dashed #8c8c8c;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.locationImg {
  width: 19px;
}
.date {
  color: #191919;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 632;
  line-height: 120%;
  letter-spacing: -0.4px;
  margin: 0;
  padding: 6px 0;
}
.shipmentInfoReceived {
  color: #191919;
  font-size: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 577;
  line-height: 150%;
  letter-spacing: 0.13px;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .mainWrapper {
    width: 100%;
    padding: 16px;
  }
  .grid {
    flex-direction: column;
    align-items: flex-start;
  }
  .header,
  .shipmentInfo,
  .shipmentNotification,
  .shipmentUpdate {
    padding: 12px;
  }
  .button {
    padding: 12px 20px;
    min-width: auto;
  }
  .printButton,
  .notificationButton {
    padding: 10px 14px;
  }
}
